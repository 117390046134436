import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';

class Authenticate extends Component {
    constructor(props) {
        super(props);
        this.userInfo=[];
        this.leaseDetails=[];
        this.ownerDetail=[];
        this.maintechDetail=[];
        this.token='';
        this.userToken=localStorage.getItem('userToken');
        this.email = localStorage.getItem('email');
        let Info = localStorage.getItem('userInfo');
        let Details = localStorage.getItem('leaseDetails');
        let prospectDetail = localStorage.getItem('prospectDetails');
        let ownerDetail = localStorage.getItem('ownerDetail');
        let maintechDetail = localStorage.getItem('maintechDetail');
        this.token=localStorage.getItem('token');
        if(Info!=null){
            this.userInfo = JSON.parse(Info);
        }        
        if(Details!=null){
            this.leaseDetails = JSON.parse(Details);
        } 
        if (prospectDetail != null) {
            this.prospectDetail = JSON.parse(prospectDetail);
        }       
        if (ownerDetail != null) {
            this.ownerDetail = JSON.parse(ownerDetail);
        }  
        if (maintechDetail != null) {
            this.maintechDetail = JSON.parse(maintechDetail);
        }  

        this.db = localStorage.getItem('db');
        if (this.db === undefined || this.db === "null") {
            this.db = '';
        }
    }
    redirect() {
        if (this.userInfo.length<=0){
            return <Navigate to={`${this.props.url}${this.db}`} />;
        }
    }
    render() {
        return (
            <div>
                {this.redirect()}
            </div>
        );
    }
}

export default Authenticate;