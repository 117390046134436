import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<HelmetProvider><App /></HelmetProvider>);

/**
 * For IE browser 
 * https://www.npmjs.com/package/polyfill
 */

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-
if(process.env.REACT_APP_PWA_ENABLE == 1 ) {
    serviceWorkerRegistration.register();
} else {
    serviceWorkerRegistration.unregister();
}
