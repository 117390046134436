// useHelmet.js
import { Helmet } from 'react-helmet-async';
export const setHelmet = (title ) => {  
    return (
      <Helmet>
        <meta name="description" content={title} />
        <title>{title}</title>
      </Helmet>
    );
  };
