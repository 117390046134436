import React, { Component } from 'react';
import logo from 'styles/logo.png';
import Authenticate from 'components/Account/components/Authenticate';
import NotFound from "../NotFound/NotFound";
import { setHelmet } from 'Common/useHelmet';
import seo from 'Common/seo.json';
/**
 * Terms and Conditions Page
 */
class TermsAndConditions extends Component {
    ownerDetailsString =(!!localStorage.getItem('ownerDetail'))?JSON.parse(localStorage.getItem('ownerDetail')):JSON.parse(localStorage.getItem('userInfo'));
    termConditionTitle = process.env.REACT_APP_TITLE+this.ownerDetailsString?.sCompanyName;
    constructor(props) {
        super(props);
        this.auth = new Authenticate();
        this.userName = '';
        if(this.auth.leaseDetails.tenant_firstname == undefined && this.auth.leaseDetails.tenant_lastname== undefined) {
            if (this.auth.prospectDetail == undefined || ( this.auth.prospectDetail.prospectInfo.tenant_firstname == undefined && this.auth.prospectDetail.prospectInfo.tenant_lastname == undefined)){
                this.userName = this.auth.userInfo.customer_name;
            } else {
                this.userName = this.auth.prospectDetail.prospectInfo.tenant_firstname + ' ' + this.auth.prospectDetail.prospectInfo.tenant_lastname;
            }
        } else {
            this.userName = this.auth.leaseDetails.tenant_firstname + ' ' + this.auth.leaseDetails.tenant_lastname;
        }
    }
    seoOperations() {
        const currentPath = window.location.pathname;
        let paymentSeoData = seo.parents.payment;
        let lastSegment = currentPath.substring(currentPath.lastIndexOf('/') + 1);
        lastSegment = lastSegment.replace(/-/g, '_');
        for (const section in paymentSeoData) {
            if (section && section == lastSegment) {
                this.termConditionTitle = paymentSeoData[section].title+this.ownerDetailsString?.sCompanyName
                break;
            }
        } 
    }
    componentDidMount() {
        this.seoOperations();  
    }
    /** For Logout */
    logout = () => {
        var sPropertyTransitId = this.auth.userInfo.sPropertyTransitId;
        localStorage.clear();
        localStorage.setItem('db',sPropertyTransitId);
        localStorage.setItem('customer',sPropertyTransitId);
        window.location.href = '/payment-login/' + sPropertyTransitId;
    }
    /** End Logout Function */

    render() {
        if (this.auth.userInfo.length <= 0 || this.auth.db === null) {
            return <NotFound />;
        }
        return (
            <div className="payment-portal-wrapper">
                {this.termConditionTitle && setHelmet(this.termConditionTitle)}
                <div className="payment-portal-header">
                    <div className="payment-portal-container-main">
                        <div className="payment-portal-logo"><img src={logo} alt="Logo" /></div>
                        <div className="header-account-links">
                            <ul>
                                <li>
                                <span className="logout-text">{this.userName}</span>
                                
                                {/* <Link to="#" onClick={this.logout}><span className="logout-text">Logout</span> <i className="fa fa-power-off fa-lg" title="Log Out"></i></Link> */}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <h1>Terms &amp; Conditions</h1>
                <div className="container payment-portal-container">
                    <div className="content-lift site-content">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="terms-content-area">
                                    <h3>1. Assent to Terms</h3>
                                    <p>Before using this site, please read these terms and conditions. You signify your acceptance of these terms and conditions of service by continuing to access or use this Site or any service available on this Site. At any time and from time to time, the terms and conditions of access and use of this Site may be modified. Accordingly, please continue to review these terms and conditions of service whenever accessing or using this Site. Your use of the Site, or any service on this Site, after the modifications to these terms and conditions of service are posted will constitute your acceptance of any and all additional terms and conditions of service, as modified. If, at any time, you do not wish to accept the terms and conditions of service, you may not use the Site. Any terms and conditions proposed by you which are in addition to or which conflict with these terms and conditions of service are expressly rejected and shall be devoid of all force and effect to the fullest extent allowed by applicable law.</p>
                                    <h3>2. Description of Payment Services</h3>
                                    <p>Internet-based credit card and on-line electronic checking service is provided by PropertyBoss Solutions, LLC and Payment Processing, Inc to corporate affiliates, rental property owners, rental property managers (the providers and receivers collectively referred to as "Participating Affiliates") and their respective tenants, which facilitates various electronic payment services for monthly rents, periodic rents, security deposits, other deposits, condo fees, timeshare charges, fixed utility payments, other fixed payments and all other fees and charges related to leased space, fixed or variable (the "Service"). You, as the tenant of a Participating Landlord, are eligible to use the Services to pay your rent and any related fees covered under our service agreement with Participating Affiliates. Your credit card or on-line electronic checking payments made through the Service will be remitted to your Participating Landlord in accordance with our service agreement with the Participating Affiliates. You will receive a timely written confirmation acknowledging receipt of credit card or on-line electronic payments. Unless explicitly stated otherwise, any new features, which enhance or augment the current service offering shall be subject to the terms and conditions of service. You must obtain access to the World Wide Web and/or Internet in order to use the Service, either directly or through other devices that access web-based content. You must also pay any service fees associated with such access. In addition, you must supply all necessary equipment to connect to the World Wide Web and/or Internet, including a computer, a modem or other access devices.</p>
                                    <h3>3. Registration Obligations</h3>
                                    <p>In consideration of your use of the Service, you agree to: (i) provide information about yourself that is true, accurate, current and complete as prompted by the Service's sign up form (the "Sign Up Information") and (ii) where applicable and appropriate, maintain and promptly update the Sign Up Information to keep it true, accurate, current and complete. If you provide information that is untrue, inaccurate, not current or incomplete or if reasonable grounds exist to suspect that such information is untrue, inaccurate, not current or incomplete, your account may be suspended or terminated and any and all current or future use of the Service refused. You may not use the Service for any illegal purpose or in any manner inconsistent with the terms and conditions of service.</p>
                                    <h3>4. Fees and Payments</h3>
                                    <p>Participating Affiliates reserve the right at any time to charge and/or increase fees for access to portions of the Service or the Service as a whole. In no event will you be charged for access to portions of the Service or to the Service unless your prior consent to pay such charges is obtained. You shall pay all fees and charges incurred through your account at the rates in effect for the billing period in which such fees and charges are incurred, including but not limited to charges for any services offered through the Service by any vendor or service provider. You shall pay all applicable taxes relating to the use of the Service through your account, and the purchase of any other services. Late fees may be assessed for rent payments made by credit card or on-line electronic checking media, which occur after the late dates and or grace periods as established in our agreements with Participating Affiliates. [Certain portions of the Service or the Service as a whole may require a prepaid fee ("Prepaid Fee"). The Prepaid Fee, and all taxes and other fees related thereto will be paid by you in advance. In no event will you receive any portions of the Service or the Service as a whole if a Prepaid Fee is required unless all fees and charges payable by you are received, including the Prepaid Fee.]</p>
                                    <h3>5. Payment cancellation, Credit and Refunds</h3>
                                    <p>For payments you believe were improperly made, the Participating Affiliates may, in their sole discretion, void, rescind or issue a credit for your rent payment made through the Service at any time prior to the remittance of such rent payment to your Participating Landlord. If a rent payment dispute arises after payment is forwarded to your Participating Landlord, the responsibility to settle the rent payment dispute rests with you and Participating Landlord. You waive the right to cancel credit card payments which have been cleared through this clearing entity.</p>
                                    <h3>6. Service Cancellation</h3>
                                    <p>Either you or a Participating Affiliate may cancel use of the Service with or without cause at any time and effective immediately. You may cancel the Service by contacting a Participating Affiliate or by sending an e-mail to [admin@propertyboss.com] to request discontinuance of the Service and destroying all materials obtained from the Service. You will remain liable for all outstanding payments and fees due at the time of cancellation. The Service may be terminated immediately without notice if you, in the Participating Landlord's sole discretion, fail to comply with any provision of these terms and conditions of service. Upon termination by you or upon notice of termination, you must destroy promptly all materials obtained from the Service and any copies thereof. Sections 9, 11, 13 and 14 shall survive any termination of the terms and conditions of service.</p>
                                    <h3>7. Membership Account, Password and Security</h3>
                                    <p>You will create a password and account designation when completing the sign up process for the Service. You are solely responsible for maintaining the confidentiality of your password and account, and are fully responsible for all activities occurring under your password or account. You agree to notify a Participating Affiliate immediately if you notice unauthorized use of your password or account or any other breach of security. A Participating Affiliate cannot and will not be liable for any damage or loss arising from your failure to comply with this Section.</p>
                                    <h3>8. License</h3>
                                    <p>You acquire absolutely no rights or licenses in or to the Service and materials contained within the Service other than the limited right to utilize the Service in accordance with the terms and conditions of service. Should you choose to download content from the Service, you must do so in accordance with the terms and conditions of service. Such download is licensed to you only for your own personal, noncommercial use in accordance with the terms and conditions of service and does not transfer any other rights to you.</p>
                                    <h3>9. Intellectual Property</h3>
                                    <p>This Site and all Site design, including but not limited to text, content, photographs, video, audio, interfaces, graphics and the selection and the arrangement thereof is protected by patents, copyrights, trademarks, service marks, international treaties and/or other proprietary rights and laws of the US and other countries. This Service is also protected as a collective work or compilation under US copyright and other laws and treaties. All individual articles of information, policies and other elements making up the Service are also copyrighted works. You agree to abide by all applicable patent, trademark, copyright and other laws, as well as any additional patent, trademark and copyright notices or restrictions contained in the Service. Any use of materials on this Site, other than as permitted under Section 2 of this agreement including reproduction, modification, distribution, or republication, without prior written permission of a Participating Affiliate is absolutely prohibited.</p>
                                    <h3>10. Disclaimer</h3>
                                    <p>The information, services, products, and materials contained in this Site, including, without limitation, text, graphics, and links, are provided on an "AS IS" basis with no warranty. To the maximum extent permitted by law, the Participating Affiliates disclaim all representations and WARRANTIES, EXPRESS OR IMPLIED, with respect to such information, services, products, and materials, including but not limited to WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, title, non-infringement, freedom from computer virus, and implied warranties arising from course of dealing or course of performance. In addition, the Participating Affiliates do not represent or warrant that the information accessible via this Site is accurate, complete or current.</p>
                                    <h3>11. Limitation on Liability</h3>
                                    <p>To the maximum extent permitted by law, the Participating Affiliates shall not be liable for any direct, indirect, special, punitive, incidental, exemplary or consequential damages, or any other such damages whatsoever, even if a Participating Affiliate has been previously advised of the possibility of such damages, whether in an action under contract, tort, statute, equity or any other federal or state law theory, arising out of or in connection with the use, inability to use, or performance of the information, services, products, and materials available from this Site. The Participating Affiliates make no warranties regarding the quality, reliability, timeliness or security of the World Wide Web, the Internet and other globally linked computer networks, or the web sites established thereon including the Site, will be uninterrupted or error free.</p>
                                    <h3>12. External Sites</h3>
                                    <p>This Site may include links to other sites on the World Wide Web and/or Internet that are owned and operated by third party vendors and other third parties (the "external sites"). You acknowledge and agree that the Participating Affiliates are not responsible for the availability of, or the content located on or through, any external site.</p>
                                    <h3>13. Indemnification</h3>
                                    <p>You agree, at your own expense, to indemnify, defend and hold harmless the Participating Affiliates and their employees, representatives, agents and suppliers, against any claim, suit, action or other proceeding brought against the Participating Affiliates, their employees, representatives, suppliers and agents, by a third party, to the extent that such claim, suit, action or other proceeding brought against the Participating Affiliates, their employees, representatives, suppliers and agents is based on or arises in connection with the Service, or any links on the Service, including, but not limited to: (i) your use or someone using your computer for use of the Service; (ii) your use or someone using your account, where applicable; (iii) a violation of the terms and conditions of service by you or anyone using your computer (or account, where applicable); (iv) a claim that any use of the Service by you or someone using your computer (or account, where applicable) infringes any intellectual property right of any third party, or any right of personality or publicity, is libelous or defamatory, or otherwise results in injury or damage to any third party; (v) any deletions, additions, insertions or alterations to, or any unauthorized use of, the Service by you or someone using your computer (or account, where applicable); (vi) any misrepresentation, including false or inaccurate Sign Up Information, or breach of representation or warranty made by you contained herein; or (vii) any breach of any covenant or agreement to be performed by you under these terms and conditions of service. You agree to pay any and all costs, damages and expenses, including, but not limited to, reasonable attorneys' fees and costs awarded against or otherwise incurred by or in connection with or arising from any such claim, suit, action or proceeding attributable to any such claim.</p>
                                    <h3>14. Jurisdiction and Governing Law</h3>
                                    <p>You irrevocably agree that all actions or proceedings arising out of, from or related to these terms and conditions of service or the Service shall be litigated in local, state or federal court within the State of South Carolina. You further hereby consent and submit to the jurisdiction of any local, state or federal courts located within said state and hereby waive any right to transfer or change the venue of any such litigation. All actions or proceedings arising out of, from or related to these terms and conditions of service or the Service shall be governed and controlled by application of the laws of the State of South Carolina as to interpretation, enforcement, validity, construction, effect and in all other respects.</p>
                                    <h3>15. Miscellaneous</h3>
                                    <p>You acknowledge that the Participating Affiliates have the right to change the content or technical specifications of any aspect of the Service at any time at the sole discretion of the Participating Affiliates. You further accept that any such changes may result in your being unable to access the Service.</p>
                                    <h3>16. Captions and Headings</h3>
                                    <p>The captions and headings appearing in terms and condition of service are for reference only and will not be considered in construing this agreement.</p>
                                    <h3>17. Severability</h3>
                                    <p>If any provision of the terms and conditions of service, or the application thereof, is found invalid or unenforceable, that provision will be amended to achieve as nearly as possible the same economic effect as the original provision and the remainder of this agreement will remain in full force.</p>
                                    <h3>18. Entire Agreement</h3>
                                    <p>These terms and conditions of service contain the entire understanding and agreement between the you and the Participating Affiliates with respect to its subject matter, superseding all prior or contemporaneous representations, understandings, and any other oral or written agreements between the parties with respect to such subject matter.</p>
                                    <h3>19. Consent Representations and Warranties</h3>
                                    <p>You represent and warrant that you have read, acknowledge and agree to be bound by the terms and conditions of the Service. You further represent and agree that: (i) you have the power and authority to enter into this agreement; (ii) you are at least eighteen (18) years old; (iii) you will comply with all US law regarding the transmission of any data obtained from the Service in accordance with the terms and conditions of service, (iv) you will not use the Service for illegal purposes, and (v) you will not interfere or disrupt networks connected to the Service.</p>
                                    <h3>20. Privacy Notice - Information Collection and Use</h3>
                                    <p>The copyright owner indicated below ("We") is the sole owner of the information collected on this site. We will not sell, share, or rent this information to others in ways different from what is disclosed in this statement. We collect information from our users at several different points on our website.</p>
                                    <h3>21. Cookies</h3>
                                    <p>A cookie is a piece of data stored on the user’s hard drive containing information about the user. Usage of a cookie is in no way linked to any personally identifiable information while on our site. Once the user closes their browser, the cookie simply terminates. For instance, by setting a cookie on our site, the user would not have to log in a password more than once, thereby saving time while on our site. Cookies also enable us to track and target the interests of our users to enhance the experience on our site. Some of our business partners use cookies. We have no access to or control over these cookies.</p>
                                    <h3>22. Log Files</h3>
                                    <p>Like most standard Web site servers we use log files. This includes internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, platform type, date/time stamp, and number of clicks to analyze trends, administer the site, track user’s movement in the aggregate, and gather broad demographic information for aggregate use.</p>
                                    <h3>23. Links</h3>
                                    <p>This web site contains links to other sites. Please be aware that we are not responsible for the privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of each and every web site that collects personally identifiable information. This privacy statement applies solely to information collected by this web site.</p>
                                    <h3>24. Legal Disclaimer</h3>
                                    <p>Though we make every effort to preserve user privacy, we may need to disclose personal information when required by law wherein we have a good-faith belief that such action is necessary to comply with a current judicial proceeding, a court order or legal process served on our Web site.</p>
                                    <h3>25. Notification of Changes</h3>
                                    <p>If we decide to change our privacy policy, we will modify this public document so our users are always aware of what information we collect, how we use it, and under circumstances, if any, we disclose it.</p>
                                    <div className="payment-portal-disclaimer">
                                        <img src={logo} alt="Logo" />
                                    </div>  
                                </div>                                                         
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default TermsAndConditions;